


// connections = [];

// //worker connect and save connection
// self.onconnect = connectEvent => {
//     const port = connectEvent.ports[0];
//     port.start();
//     connections.push(port);
//     console.log(connections.length)
// };


// // SSE connect and boardcast to worker
// const sse = new EventSource('sse domain')
// sse.onmessage = (event) => {
//     if (connections.length != 0) {
//         connections.forEach(connection => {
//             connection.postMessage(event.data);
//         });
//     }
// }